
.ycv-block-grid{
    &+.ycv-block-grid{
        border-top: solid 1px #000;
    }
    &>div+div{
        border-left: solid 1px #ccc;
    }
    &>div>div{
        padding-top: 5px;
        padding-bottom: 8px;
    }
    &>div+div{
        &>div+div{
            border-top: solid 1px #999;
        }
    }
    & img{
        width:100%;
    }
}
.ycv_imgc_xs{
    margin: 0px 15px;
    height: 20px;
    background-position: center;
    border: solid 1px #888;
}
.ycv_div_xs{
    width:100%;
}
