@charset "UTF-8";
/* component YCVBlock */
$block-marg:20px;

.ycv-block{
    margin-bottom: $block-marg;
    &>div{
        box-shadow: 0px 0px 2px rgba(0,0,0,0.6),6px 4px 8px rgba(0,0,0,0.6);
    }
    &:first-child{
        margin-top: $block-marg;
    }
}

.ycv-block-head{
    // background-color:rgba(0,0,0,0.8);
    background-image:linear-gradient(to right,rgba(0,0,0,0.7),rgba(0,0,0,0.8),rgba(0,0,0,0.7));
    color:#fff;
    text-align: center;
}
.ycv-block-body{
    background-color:rgba(255,255,255,0.9);
    color:#000;
}

.ycv-block-perso>div{
    background-color:rgba(255,255,255,0.9);
    padding-top:4px;
    padding-bottom:6px;
}
