@charset "UTF-8";
/* component YCVHead */

.ycv-head{
    padding: 0px 20px 0px 15px;
    background-image: linear-gradient(to top,#ccc,#fff);
}

.ycv-ikonz{
    padding-top: 3px;
    padding-bottom: 3px;
    display: inline-block;
    cursor: pointer;
    &+.ycv-ikonz{
        border-left: solid 1px;
    }
}
.ycv-ikonz-enfr{
    padding: 2px 5px 2px 0px;
    &>span{
        padding: 0px 3px 3px;
        display: inline-block;

        &.enfr-on{
            border:solid 1px;
        }
        &.enfr-off{
        }
        &>img{
            height:18px;
        }
    }
}
.ycv-ikonz-pdf{
    padding: 3px 5px;
    color: #337ab7;
}
.ycv-ikonz-showhide{
    padding: 3px 8px;
}
.ycv-tags{
    float: right;
}
