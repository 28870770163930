@charset "UTF-8";
/* component YCVLayout */

.ycv-layout{
    width: 100vw;
    height: 100vh;
    box-sizing: content-box;
    overflow: hidden;
}
.ycv-layout>*{
    box-sizing: content-box;
    position: absolute;
}
.ycv-layout-head,.ycv-layout-head>div{
}
.ycv-layout-head{
    position: absolute;
    width: 100%;
    z-index: 1000;
    background-color: #fee;
    &>div{
    }
}
// .ycv-layout-head>div{
//     height: 20px;
// }
.ycv-layout-body{
    position: absolute;
    // background-color: #696;
    overflow: auto;
    &>div{
        // background-color: #eef;
    }
}
.ycv-layout-body>div{
}

.ycv-layout-back,.ycv-layout-front{
    left:0px;
    top:0px;
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.ycv-layout-back{
    position: absolute;
    border: none;
}
.ycv-layout-front{
    z-index: 1000;
}
